<template>
  <div>
    <div class="main" :class="!isApp ? 'appTop' : ''">
      <!-- <div class="main-content">
        2022年9月30日16:00到2022年10月6日22:00， 转发公众号
        <span> [看漫艺术平台]</span
        >活动文章到<span>200人以上微信数藏群</span>或<span>500人以上QQ数藏群</span>，
        附带“看漫数藏豪礼放送，参与就送限定藏品、现金红包！”文案，保持<span>满3分钟</span>截图。
        上传截图后，每日22:00统一人工审核，通过后可陆续购买<span>「宝典盲盒」</span>。
        每转发1个群，可购买一份盲盒，通过此规则<span>最多可购买3份盲盒</span>。
      </div> -->
      <div class="main_title">请选择截图</div>
      <div class="main_sub">图片限5M大小，最多上传1张</div>
      <div class="">
        <van-uploader
          :after-read="afterRead"
          :max-size="isOverSize"
          v-model="fileList"
          :max-count="1"
        >
          <!-- accept="image/*" -->
          <div class="upbox"><van-icon name="plus" /></div>
        </van-uploader>
      </div>
    </div>
    <div class="btn" :style="{ opacity: fileList.length ? 1 : 0.7 }" @click="uploadFn">上传</div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import MD5 from 'crypto-js/md5';
import { Toast, toast } from 'vant';
import { nftUtils } from '@/utils';
import { audituploadsts, indexentranceshow, uploadimagesave } from '@/service/home';
import OSS from 'ali-oss';
export default {
  setup() {
    const state = reactive({
      fileList: [],
      image_list: [],
      mark: false,
      isLogin: nftUtils.isLogin(),
      isApp: nftUtils.isInApp()
    });
    const router = useRouter();

    onMounted(async () => {
      // const { data } = await indexentranceshow();
      // state.mark = data.mark;
    });
    const isOverSize = file => {
      console.log(file);
      const maxSize = 5 * 1024 * 1024;
      // alert(file.size);
      if (file.size >= maxSize) {
        toast('图片限制5M以内,请重新选择');
      }

      return file.size >= maxSize;
    };

    const afterRead = file => {
      console.log(file, state.fileList);
    };

    const uploadFn = async () => {
      // if (!state.mark) {
      //   Toast('活动已结束');
      //   return;
      // }
      if (!state.isLogin) {
        Toast('请先登录');
        router.push({ path: `/login` });
        return;
      }
      if (state.fileList.length) {
        // 避免重复上传，重置数组
        state.image_list = [];
        Toast.loading({
          message: '图片上传中',
          forbidClick: true,
          loadingType: 'spinner',
          duration: 0
        });
        // console.log(state.fileList[0]);
        let backList = state.fileList.map(file => {
          return new Promise((resolve, reject) => {
            // console.log(file);
            getImage(file.file, resolve, reject);
          });
        });

        Promise.all(backList).then(() => {
          // console.log(state.image_list, './///');
          uploadimagesave({ image_list: state.image_list }).then(res => {
            Toast.clear();
            if (res.status === 0) {
              Toast('上传成功');
              setTimeout(() => {
                router.go(-1);
              }, 1500);
            } else {
              Toast(res.message);
            }
          });
        });
        // getImage(state.fileList[0].file);
      } else {
        Toast('请选择上传图片');
        return;
      }
    };

    const defaultCreateFilename = file => {
      const md5Str = file.name + file.lastModified + new Date().getTime() + file.size;
      return MD5(md5Str).toString() + file.name.substring(file.name.lastIndexOf('.'));
    };

    //sts 上传图片
    const getImage = async (file, resolve, reject) => {
      const { data } = await audituploadsts();
      const {
        bucket,
        filePath,
        link,
        token: { AccessKeyId, AccessKeySecret, SecurityToken },
        region
      } = data;
      const client = new OSS({
        accessKeyId: AccessKeyId,
        accessKeySecret: AccessKeySecret,
        stsToken: SecurityToken,
        bucket,
        region
      });
      client
        .put(filePath, file)
        .then(() => {
          console.log(link, filePath, file);
          resolve(link);
          state.image_list.push(link);
          // this.uploaded && this.uploaded({ url: link, filePath, file });
        })
        .catch(err => {
          console.error(err);
          reject();
        });
    };

    return {
      ...toRefs(state),
      afterRead,
      uploadFn,
      isOverSize,
      getImage,
      defaultCreateFilename
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/mixin.scss';
.appTop {
  padding-top: 70px !important;
}
.main-content {
  font-size: 14px;
  margin-bottom: 14px;
  span {
    color: $primary;
  }
}
.btn {
  width: 252px;
  height: 44px;
  background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
  border-radius: 4px 4px 4px 4px;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1c172a;
  position: fixed;
  bottom: 44px;
  left: 50%;
  transform: translate(-50%, -0%);
}
.main {
  padding: 16px 11px;
  .main_title {
    font-size: 14px;
    font-weight: 600;
  }
  .main_sub {
    font-size: 12px;
    margin: 10px 0 16px;
    opacity: 0.7;
  }
}
.upbox {
  width: 78px;
  height: 78px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px dashed #999999;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}
</style>
